import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a href="https://youtu.be/rEq1Z0bjdwc" sx={{
      textDecoration: "none",
      color: "inherit !important"
    }} title="a link? here?">
  👋 Hello there.
    </a>
    <p>{`I’m`}{` `}{`Piotr`}{` `}{`Monwid`}{`‑`}{`Olechnowicz. Nice to have you here.`}</p>
    <p>{`I currently specialize in React and TypeScript. I maintain
`}<a parentName="p" {...{
        "href": "https://github.com/hasparus/theme-ui"
      }}>{`Theme UI`}</a>{` and contribute here and
there to other libraries. I build software at
`}<a parentName="p" {...{
        "href": "https://www.flick.tech/"
      }}>{`Flick`}</a>{`, and`}{` `}{`I’m one of `}<a parentName="p" {...{
        "href": "https://typescript.community/"
      }}>{`Wrocław
TypeScript`}</a>{` nerds.`}</p>
    <p>{`This is my space on the internet, where I`}{` `}<a parentName="p" {...{
        "href": "/writing"
      }}>{`write blog posts`}</a>{`,
`}<a parentName="p" {...{
        "href": "/reading"
      }}>{`collect`}{` `}{`things`}</a>{` that influenced me, and comment on them.
I`}{` `}{`try`}{` `}{`to`}{` `}{`learn in public.`}</p>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "/notes"
      }}>{`my notes`}</a>{` — I started publishing little bits of my Foam
workspace recently, and the graph overview is pretty rad.`}</p>
    <p>{`Feel free to ping me on Twitter if you like (or hate) anything you read
here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      