import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I gave a few talks. `}<a parentName="p" {...{
        "href": "/speaking/matryoshka-code"
      }}>{`Matryoshka Code rant`}</a>{` is
probably the best one.`}</p>
    <h4 {...{
      "id": "more-links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#more-links",
        "aria-label": "more links permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`More Links`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/notes"
        }}>{`notes`}</a>{` — my Foam workspace`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/writing"
        }}>{`writing`}</a>{` — my articles`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/speaking"
        }}>{`speaking`}</a>{` — talk notes and abstracts`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/reading"
        }}>{`reading`}</a>{` — better articles`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/resume"
        }}>{`resume`}</a>{` — linkedin bothers me a bit`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/music"
        }}>{`music`}</a>{` — awesome mix vol. 3 🎧`}</li>
    </ul>
    {
      /*
      - [now](/now) -- check out what I'm up to at the moment
      - [chat](/chat) -- contact forms are boring, websockets are rad
      - [games](/games) -- I made some stuff for fun
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      